<template>
<div>
  <div class="access">
    <img class="parking" src="../assets/re71rs.webp">
    <h2 class="name_h2">ポテンザ RE-71RS</h2>

    <p class="access_p">
    ポテンザ RE-71RSは公道・サーキットでの走行に向いています。<br>
    ブレーキングやコーナリングのグリップに特化しているため、走りを楽しみたいクルマにおすすめです。<br>
    前モデルのRE-71Rよりも摩耗性能が改善されました。
    </p>

    <div class="square-01">
    </div>

    <h3 class="recom_h3">推奨車種</h3>
    <p class="recom">スポーツ</p>

    <div class="charge2">
    <div class="charge_table">
      <h2 class="charge_table_h2"><span class="charge_table_span">サイズ表・メーカー希望小売価格</span><br>※4本購入で値引きできます</h2>
      <table class="biz-hour">
        <tbody>
          <tr>
            <th>サイズ</th>
            <th>税込<span class="price_span"> 1本</span></th>
          </tr>
          <tr>
            <td>20インチ</td>
          </tr>
          <tr>
            <td>285/35R20 100W</td>
            <td class="reds"><span class="red_p">¥</span>112,200</td>
          </tr>
          <tr>
            <td>255/40R20 97W</td>
            <td class="reds"><span class="red_p">¥</span>93,060</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>19インチ</td>
          </tr>
          <tr>
            <td>275/30R19 92W</td>
            <td class="reds"><span class="red_p">¥</span>96,800</td>
          </tr>
          <tr>
            <td>265/30R19 89W</td>
            <td class="reds"><span class="red_p">¥</span>91,520</td>
          </tr>
          <tr>
            <td>285/35R19 103W	XL</td>
            <td class="reds"><span class="red_p">¥</span>98,670</td>
          </tr>
          <tr>
            <td>275/35R19 96W</td>
            <td class="reds"><span class="red_p">¥</span>90,530</td>
          </tr>
          <tr>
            <td>255/35R19 96W	XL</td>
            <td class="reds"><span class="red_p">¥</span>86,130</td>
          </tr>
          <tr>
            <td>245/35R19 93W XL</td>
            <td class="reds"><span class="red_p">¥</span>83,490</td>
          </tr>
          <tr>
            <td>235/35R19 91W	XL</td>
            <td class="reds"><span class="red_p">¥</span>79,970</td>
          </tr>
          <tr>
            <td>225/35R19 88W	XL</td>
            <td class="reds"><span class="red_p">¥</span>81,070</td>
          </tr>
          <tr>
            <td>245/40R19 98W	XL</td>
            <td class="reds"><span class="red_p">¥</span>79,200</td>
          </tr>
          <tr>
            <td>225/40R19 93W	XL</td>
            <td class="reds"><span class="red_p">¥</span>74,030</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>18インチ</td>
          </tr>
          <tr>
            <td>295/30R18 94W</td>
            <td class="reds"><span class="red_p">¥</span>94,930</td>
          </tr>
          <tr>
            <td>285/30R18 93W</td>
            <td class="reds"><span class="red_p">¥</span>89,320</td>
          </tr>
          <tr>
            <td>295/35R18 99W</td>
            <td class="reds"><span class="red_p">¥</span>95,920</td>
          </tr>
          <tr>
            <td>275/35R18 95W</td>
            <td class="reds"><span class="red_p">¥</span>83,490</td>
          </tr>
          <tr>
            <td>265/35R18 97W	XL</td>
            <td class="reds"><span class="red_p">¥</span>80,850</td>
          </tr>
          <tr>
            <td>255/35R18 94W	XL</td>
            <td class="reds"><span class="red_p">¥</span>76,560</td>
          </tr>
          <tr>
            <td>245/35R18 92W	XL</td>
            <td class="reds"><span class="red_p">¥</span>78,430</td>
          </tr>
          <tr>
            <td>255/40R18 99W	XL</td>
            <td class="reds"><span class="red_p">¥</span>80,190</td>
          </tr>
          <tr>
            <td>235/40R18 95W	XL</td>
            <td class="reds"><span class="red_p">¥</span>67,650</td>
          </tr>
          <tr>
            <td>225/40R18 92W	XL</td>
            <td class="reds"><span class="red_p">¥</span>66,880</td>
          </tr>
          <tr>
            <td>215/40R18 89W	XL</td>
            <td class="reds"><span class="red_p">¥</span>65,340</td>
          </tr>
          <tr>
            <td>245/45R18 100W XL</td>
            <td class="reds"><span class="red_p">¥</span>66,330</td>
          </tr>
          <tr>
            <td>225/45R18 95W	XL</td>
            <td class="reds"><span class="red_p">¥</span>59,510</td>
          </tr>
          <tr>
            <td>215/45R18 93W	XL</td>
            <td class="reds"><span class="red_p">¥</span>54,560</td>
          </tr>
          <tr>
            <td>225/50R18 95W</td>
            <td class="reds"><span class="red_p">¥</span>50,930</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>17インチ</td>
          </tr>
          <tr>
            <td>255/40R17 98W	XL</td>
            <td class="reds"><span class="red_p">¥</span>61,050</td>
          </tr>
          <tr>
            <td>245/40R17 91WL</td>
            <td class="reds"><span class="red_p">¥</span>57,970</td>
          </tr>
          <tr>
            <td>235/40R17 90W</td>
            <td class="reds"><span class="red_p">¥</span>55,330</td>
          </tr>
          <tr>
            <td>215/40R17 87W	XL</td>
            <td class="reds"><span class="red_p">¥</span>50,710</td>
          </tr>
          <tr>
            <td>245/45R17 95W</td>
            <td class="reds"><span class="red_p">¥</span>61,270</td>
          </tr>
          <tr>
            <td>235/45R17 94W</td>
            <td class="reds"><span class="red_p">¥</span>54,560</td>
          </tr>
          <tr>
            <td>225/45R17 94W	XL</td>
            <td class="reds"><span class="red_p">¥</span>51,480</td>
          </tr>
          <tr>
            <td>215/45R17 91W	XL</td>
            <td class="reds"><span class="red_p">¥</span>48,180</td>
          </tr>
          <tr>
            <td>205/45R17 88W	XL</td>
            <td class="reds"><span class="red_p">¥</span>47,740</td>
          </tr>
          <tr>
            <td>195/45R17 81W</td>
            <td class="reds"><span class="red_p">¥</span>44,440</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>16インチ</td>
          </tr>
          <tr>
            <td>225/45R16 89W</td>
            <td class="reds"><span class="red_p">¥</span>46,750</td>
          </tr>
          <tr>
            <td>215/45R16 86W</td>
            <td class="reds"><span class="red_p">¥</span>45,210</td>
          </tr>
          <tr>
            <td>205/45R16 87W	XL</td>
            <td class="reds"><span class="red_p">¥</span>43,670</td>
          </tr>
          <tr>
            <td>195/45R16 80W</td>
            <td class="reds"><span class="red_p">¥</span>37,950</td>
          </tr>
          <tr>
            <td>245/50R16 98V</td>
            <td class="reds"><span class="red_p">¥</span>48,400</td>
          </tr>
          <tr>
            <td>225/50R16 92V</td>
            <td class="reds"><span class="red_p">¥</span>41,140</td>
          </tr>
          <tr>
            <td>205/50R16 87V</td>
            <td class="reds"><span class="red_p">¥</span>36,300</td>
          </tr>
          <tr>
            <td>195/50R16 84V</td>
            <td class="reds"><span class="red_p">¥</span>33,990</td>
          </tr>
          <tr>
            <td>205/55R16 91V</td>
            <td class="reds"><span class="red_p">¥</span>36,190</td>
          </tr>
          <tr>
            <td>195/55R16 87V</td>
            <td class="reds"><span class="red_p">¥</span>35,750</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>15インチ</td>
          </tr>
          <tr>
            <td>225/50R15 91V</td>
            <td class="reds"><span class="red_p">¥</span>40,040</td>
          </tr>
          <tr>
            <td>205/50R15 86V</td>
            <td class="reds"><span class="red_p">¥</span>33,220</td>
          </tr>
          <tr>
            <td>195/50R15 82V</td>
            <td class="reds"><span class="red_p">¥</span>27,500</td>
          </tr>
          <tr>
            <td>165/50R15 73V</td>
            <td class="reds"><span class="red_p">¥</span>25,850</td>
          </tr>
          <tr>
            <td>205/55R15 88V</td>
            <td class="reds"><span class="red_p">¥</span>32,230</td>
          </tr>
          <tr>
            <td>195/55R15 85V</td>
            <td class="reds"><span class="red_p">¥</span>27,500</td>
          </tr>
          <tr>
            <td>185/55R15 82V</td>
            <td class="reds"><span class="red_p">¥</span>28,270</td>
          </tr>
          <tr>
            <td>165/55R15 75V</td>
            <td class="reds"><span class="red_p">¥</span>22,660</td>
          </tr>
          <tr>
            <td>205/60R15 91H</td>
            <td class="reds"><span class="red_p">¥</span>29,260</td>
          </tr>
          <tr>
            <td>195/60R15 88V</td>
            <td class="reds"><span class="red_p">¥</span>27,390</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>14インチ</td>
          </tr>
          <tr>
            <td>185/55R14 80V</td>
            <td class="reds"><span class="red_p">¥</span>25,080</td>
          </tr>
          <tr>
            <td>165/55R14 72V</td>
            <td class="reds"><span class="red_p">¥</span>20,680</td>
          </tr>
          <tr>
            <td>195/60R14 86H</td>
            <td class="reds"><span class="red_p">¥</span>24,750</td>
          </tr>
          <tr>
            <td>185/60R14 82H</td>
            <td class="reds"><span class="red_p">¥</span>23,210</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>13インチ</td>
          </tr>
          <tr>
            <td>165/60R13 73H</td>
            <td class="reds"><span class="red_p">¥</span>18,040</td>
          </tr>
          <tr>
            <td>155/60R13 70H</td>
            <td class="reds"><span class="red_p">¥</span>16,060</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <CarouselsSedan/>

    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p class="address_p">静岡県御殿場市茱萸沢241-1</p>
    <a href="https://goo.gl/maps/oabDqVGbjJQn65yk8">Google Maps</a>
  </div>
  <div>
    <Footer/>
  </div>
</div>
</template>

<script>
import CarouselsSedan from '@/components/CarouselsSedan.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    CarouselsSedan,
    Footer
  }
}
</script>

<style scoped>
.recom_h3{
  text-align: left;
}
.recom{
  padding-bottom: 3rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list{
  padding-bottom: 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list2{
  padding-bottom: 6rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.access{
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
}
.access p{
  text-align:left;
}
.access_p{
  padding-bottom: 2rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.access a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.access a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.name_h2{
  padding-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 2rem;
}

.about_p{
  margin-top: -4rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding-top: 4rem;
}
.address_p{
  font-weight: 600;
  font-size: 0.8rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



.access img {
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media (max-width: 640px)
.page .moretodiscover .square-01 {
    width: 67.5vw;
    height: calc(100% - 25vw);
    position: absolute;
    top: 25vw;
    right: 0;
    background-color: #dae4e8;
}
.charge_table_h2{
  text-align: left;
}
.charge_table_span{
  font-size: 1rem;
}
.price_span{
  font-size: 0.8rem;
}
.charge_table{
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.biz-hour {
  border-collapse: collapse;
}
@media screen and (max-width: 414px) {
  .biz-hour {/* スマホのときだけ親要素いっぱいに */
    width: 100%;
  }
}

.biz-hour th, .biz-hour td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  /* ２番目以降の線 */
  /* 色はお好みで */
  border-bottom: 1px solid black;
  font-weight: bold;
}

/* 上段の背景色 */
.biz-hour th {
  /* background: black; */
  font-weight: bold;
}

/* 土曜日の文字色 */
.biz-hour th.sat {
  color: #3db2da;
}

/* 日曜日の文字色 */
.biz-hour th.sun {
  color: #e66a6a;
}
</style>
